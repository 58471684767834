import Vue from 'vue'
import App from './App.vue'
//6.引入导出的路由
import router from './router/index'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
