//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'
//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router = new VueRouter({
    //tips:不想要 #（锚点）就添加下面代码
    mode:'history',
    //4.配置路由信息
    routes :[
        {
            path: "/",
            component:() => import("../components/index.vue"),
        }
    ]
})
//5.导出路由实例
export default router
